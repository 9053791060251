<template>
	<div class="journalDetails page-content">
		<section class="header-bg" style="">
			
		</section>
		<section style="margin:auto;max-width: 1500px;margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Article
						Record-Details</strong></p>
				<input type="hidden" id="journalId" :value="journalDetails.id">
				<input type="hidden" id="journalCCount" :value="journalDetails.citationCount">
				<input type="hidden" id="journalRCount" :value="journalDetails.referenceCount">
			</div>
			<div class="layui-fluid layui-bg-gray"
				style="margin-top:40px;padding: 40px 10px 40px 10px;min-height: 800px;">
				<div class="layui-row layui-col-space10">
					<div class="layui-col-md3 leftAndCenterTopFont">
						<div class="layui-panel">
							<div style="font-size: 16px;line-height: 24px;padding: 20px;">

								<div style="font-weight: bold;font-size: 20px">Journal Impact Factor</div>


								<div style="margin-top: 10px" class="ad_fa">
									<strong>Real_time Impact Factor
									</strong>
									<p style="font-size: 13px;font-weight: bold">Calculated by AI in Contrimetric</p>

									<p style="color: #a8a8a8;">JIF(2023):<span class="layui-badge layui-bg-cyan"
											id="JIF2023" title="explain:...">?</span></p>
								</div>
								<div class="ad_fa">
									<strong>Running Journal Impact Factor</strong>
									<p style="font-size: 13px;font-weight: bold">Supplied by AI in Contrimetric</p>
									<p style="color: #a8a8a8;">JIF(2022):<span
											style="font-size: 24px">
											{{JIF && JIF.JIF2 ? JIF.JIF2 : ""}}
											</span> </p>
								</div>
								<!--								<#&#45;&#45;<div>-->
								<!--								<strong>Journal: </strong>-->
								<!--								<p style="color: #a8a8a8;">${journalDetails.sourceTitle}</p>-->
								<!--							</div>-->
								<!--								<div><strong>Volume & Issue: </strong>-->
								<!--									<p style="color: #a8a8a8;">${journalDetails.volumeV} , ${journalDetails.issueI}</p>-->
								<!--								</div>-->
								<!--								<div>-->
								<!--									<strong>Publisher Year: </strong>-->
								<!--									<p style="color: #a8a8a8;">${journalDetails.publicationYear}</p>-->
								<!--								</div>-->
								<!--								<div><strong>DOI: </strong>-->
								<!--									<p style="color: #a8a8a8;" id="doi"-->
								<!--									   data-doi="${journalDetails.doiD}">${journalDetails.doiD}</p>-->
								<!--								</div>-->
								<!--								<div><strong>Document Type: </strong>-->
								<!--									<p style="color: #a8a8a8;">${journalDetails.documentType}</p>-->
								<!--								</div>&ndash;&gt;-->
							</div>
						</div>


						<div class="layui-panel" style="margin-top: 40px;">
							<div style="font-size: 16px;line-height: 24px;padding: 20px;">

								<div style="font-weight: bold;font-size: 20px">Publication</div>



								<div style="margin-top: 10px" class="ad_fa">
									<strong>Journal: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.sourceTitle}}</p>
								</div>
								<div class="ad_fa">
									<strong>Issue: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.issueI}}</p>
								</div>
								<div class="ad_fa">
									<strong>Pages: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.startPage}}-{{journalDetails.endPage}}
									</p>
								</div>
								<div class="ad_fa">
									<strong>Year: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.publicationYear}}</p>
								</div>
								<div class="ad_fa"><strong>DOI: </strong>
									<p style="color: #a8a8a8;" id="doi" data-doi="${journalDetails.doiD}">
										{{journalDetails.doiD}}
									</p>
								</div>
								<div class="ad_fa"><strong>Document: Article/Review: </strong>
									<p style="color: #a8a8a8;" id="doi" data-doi="${journalDetails.doiD}">Article/Review
									</p>
								</div>
								<div class="ad_fa">
									<strong>ISSN: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.issnI}}</p>
								</div>
								<div class="ad_fa">
									<strong>EISSN: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.eissnE}}</p>
								</div>

								<div class="ad_fa"><strong>Subjects: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.wosCategories}}</p>
								</div>

							</div>
						</div>

						<div class="layui-panel" style="margin-top: 40px;">
							<div style="font-size: 16px;line-height: 24px;padding: 20px;">

								<div style="font-weight: bold;font-size: 20px">Publisher</div>

								<div style="margin-top: 10px" class="ad_fa">
									<strong>Publisher: </strong>
									<p style="color: #a8a8a8;">{{journalDetails.publisherP}}</p>
								</div>

								<div class="ad_fa">
									<strong>Address: </strong>
									<p style="color: #a8a8a8;">-</p>
								</div>

								<div class="ad_fa">
									<strong>Country: </strong>
									<p style="color: #a8a8a8;">-</p>
								</div>
							</div>
						</div>
					</div>
					<div class="layui-col-md6">
						<div class="layui-panel">
							<div class="leftAndCenterTopFont" style="padding: 20px;">
								<p
									style="color: #1da6b8;font-size: 20px;font-weight: bold;font-family: 'Yantramanav', sans-serif;">
									<span>
										<i style="font-size: 16px;color: black" class="${lock}"></i>
									</span>
									{{journalDetails.articleTitle}}
								</p>
							</div>
							<div style="padding: 20px;line-height: 22px;" class="authors leftAndCenterTopFont">
								<!--								<#list authList as item>-->
								<a style="margin-right: 20px;text-decoration: underline;"
									v-for="(item,index) of authList" :key="index"
									:href="'journalSource?authorFullNames='+item+'&authorOrcid='+authOrcidList[index][item]"
									target="_blank" class="authors">{{item}}<span class="layui-badge layui-bg-gray">
										{{index+1}}</span>;</a>
								<!--							</#list>-->
							</div>

							<div style="padding: 20px;">
								<div class="leftAndCenterTopFont">
									<p style="font-size: 16px"><strong>Affiliations</strong></p>
									<div style="text-align: justify;line-height: 24px;">
										<!--									<#if affiliationsList??>-->
										<!--									<#list affiliationsList as item>-->
										<!--									<#if (item_index < 3)>-->
										<p style="margin-right: 20px;" v-for="(item,index) of affiliationsList"
											:key="index">
											<span v-if="index<3">
												<span class="layui-badge layui-bg-gray">{{index+1}}</span>{{item}}
											</span>
											<span :class="affShow?'layui-hide':''" v-else>
												<span class="layui-badge layui-bg-gray">{{index+1}}</span>{{item}}
											</span>
										</p>
										<p class="showMore float-hand-color" v-if="affiliationsList.length>3"
											@click="affShow = !affShow">
											<span style="border-bottom: solid 1px #1da6b8;" v-if="affShow">Show
												More</span>
											<span style="border-bottom: solid 1px #1da6b8;" v-else>Less</span>
											<i :class="affShow?'layui-icon layui-icon-down':'layui-icon layui-icon-up'"
												style="margin-left: 5px;"></i>
										</p>

									</div>
								</div>
								<div class="leftAndCenterTopFont" style="margin-top: 50px;">
									<p style="font-size: 16px"><strong>Abstract</strong></p>
									<article
										style="text-align: justify;line-height: 24px;font-family: 'Yantramanav', sans-serif;">
										{{journalDetails.abstractA}}
									</article>
								</div>
								<div class="leftAndCenterTopFont" style="margin-top: 50px;">
									<p style="font-size: 16px"><strong>Keywords</strong></p>
									<article style="text-align: justify;line-height: 24px;">

										<span v-if="keywordList!=null">
											<p style="margin-right: 20px;" v-for="(item,index) of keywordList"
												:key="index">
												<span class="layui-badge layui-bg-gray">{{index+1}}</span>{{item}}
											</p>
											<p style="margin-right: 20px;">
												<span class="layui-badge layui-bg-gray">{{ja.authorKeywords}}</span>
											</p>
										</span>
										<span v-else>
											No Data
										</span>
										<!--		<#else>-->
										<!--		No Data-->
										<!--	</#if>-->

									</article>
								</div>
								<div class="right layui-clear" style="margin-top: 50px;">
									<button type="button" class="layui-btn" id="viewViaPublisher">
										<i class="layui-icon bi bi-binoculars layui-font-12"></i>View via
										Publisher</button>

									<button type="button" class="layui-btn" id="cite" @click="isChangePassword = true">
										<i class="layui-icon bi bi-link-45deg layui-font-12"></i>
										Cite
									</button>
									<button type="button" class="layui-btn" id="report" @click="isGenerate = true">
										<i class="layui-icon bi bi-book layui-font-12"></i>
										Generate Report</button>
									<button type="button" class="layui-btn" id="fullTextInPDF">
										<i class="layui-icon bi bi-filetype-pdf layui-font-12"></i>
										Full Text in PDF</button>



								</div>
								<!--	<#&#45;&#45;                         layui-tab-brief&ndash;&gt;-->
								<div class="layui-tab" id="layui-tab-warp" lay-filter="docDemoTabBrief"
									style="margin-top: 120px;">
									<ul class="layui-tab-title">
										<li v-for="(item,index) of layuiTitleList" :key="index"
											:class="layuiTitleIndex==index?'layui-this':''"
											style="width: 45%;font-weight: bold" @click="layuiTitleIndex=index">
											{{item.a}}({{index==0?journalDetails.referenceCount:journalDetails.citationCount}})
										</li>
									</ul>
									<div class="layui-tab-content">
										<div :class="layuiTitleIndex==0 ? 'layui-tab-item layui-show':'layui-tab-item'">

											<div class="layui-tab layui-tab-brief">
												<ul class="layui-tab-title">
													<li :class="referencesIndex!=index?'':'layui-this'"
														v-for="(item,index) of referencesList" :key="index"
														@click="referencesBtn(index)">
														{{item.a}}
													</li>
												</ul>
												<div class="layui-tab-content">
													<div
														:class="referencesIndex==0 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<!--							<img class="re_all_loading" src="../../assets/images/loading.gif" style="width: 70px;margin-left: 45%;">-->
														<div id="reference_all_content">
															<re-or-ci-item v-for="(item,index) of referenceList"
																:key="index" :item="item">
															</re-or-ci-item>

														</div>
														<div id="reference_all_page">
															<el-pagination background layout="prev, pager, next"
																:total="parseInt(journalDetails.referenceCount)"
																v-model:current-page="referenceList.page"
																@size-change="" @current-change="pageCliOne" />
														</div>
													</div>
													<div
														:class="referencesIndex==1 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<div id="reference_all_content">
															<re-or-ci-item
																v-for="(item,index) of referencesListSelect.scopusAll.scopusList"
																:key="index" :item="item">
															</re-or-ci-item>

														</div>
													</div>
													<div
														:class="referencesIndex==2 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<re-or-ci-item
															v-for="(item,index) of referencesListSelect.otherAll.otherList"
															:key="index" :item="item">
														</re-or-ci-item>
													</div>
													<div
														:class="referencesIndex==3 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<re-or-ci-item
															v-for="(item,index) of referencesListSelect.wosAll.wosList"
															:key="index" :item="item">
														</re-or-ci-item>
													</div>
												</div>
											</div>
										</div>
										<div :class="layuiTitleIndex==1 ? 'layui-tab-item layui-show':'layui-tab-item'">
											<div class="layui-tab layui-tab-brief">
												<ul class="layui-tab-title">
													<li :class="citationsIndex!=index?'':'layui-this'"
														v-for="(item,index) of citationsList" :key="index"
														@click="citationsBtn(index)">
														{{item.a}}
													</li>
												</ul>
												<div class="layui-tab-content">
													<div
														:class="citationsIndex==0 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<!--							<img class="ca_all_loading" src="../../assets/images/loading.gif" style="width: 70px;margin-left: 45%;">-->
														<div id="catation_all_content">
															<re-or-ci-item v-for="(item,index) of citsationsList"
																:key="index" :item="item">

															</re-or-ci-item>
														</div>
														<div id="catation_all_page">
															<el-pagination background layout="prev, pager, next"
																:total="parseInt(journalDetails.citationCount)"
																v-model:current-page="citsationsList.page"
																@size-change="" @current-change="pageCliTwo" />
														</div>
													</div>
													<div
														:class="citationsIndex==1 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<re-or-ci-item
															v-for="(item,index) of citationsListSelect.scopusAll.scopusList"
															:key="index" :item="item">

														</re-or-ci-item>
													</div>
													<div
														:class="citationsIndex==2 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<re-or-ci-item
															v-for="(item,index) of citationsListSelect.otherAll.otherList"
															:key="index" :item="item">

														</re-or-ci-item>
													</div>
													<div
														:class="citationsIndex==3 ? 'layui-tab-item layui-show':'layui-tab-item'">
														<re-or-ci-item
															v-for="(item,index) of citationsListSelect.wosAll.wosList"
															:key="index" :item="item">

														</re-or-ci-item>
													</div>
													<!-- <div
														:class="citationsIndex==4 ? 'layui-tab-item layui-show':'layui-tab-item'">
														Content-5
													</div> -->
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>

						</div>
					</div>




					<div class="layui-col-md3" id="rightAll">
						<div class="layui-panel">
							<table class="layui-table" style="text-align: center;margin: 0;">
								<colgroup>
									<col width="150">
									<col width="150">
								</colgroup>
								<thead>
									<tr class="layui-bg-black">
										<th colspan="2"
											style="text-align: center;font-size: 22px;font-family: 'Source Sans Pro', sans-serif;, cursive;">
											Contribution Factor
										</th>
									</tr>
								</thead>
								<tbody>

									<tr class="layui-bg-black loginFalse">
										<td style="text-align: center;" class="cfYellow"><strong>CF1 </strong></td>
										<td style="text-align: center; " class="cfGreen"><strong>CF2</strong></td>
									</tr>


									<tr v-show="!isLogin">
										<td colspan="2">
											<a href="/login">
												<button type="button" class="layui-btn"
													style="background-color: #1da6b8;">Log in
													to
													view<i class="layui-icon layui-icon-password"></i></button>
											</a>
										</td>
									</tr>
									<tr v-show="isLogin">
										<td class="c1Value" data-value="${authDetails.cOne}">
											<strong :style=" {color:( authDetails.cOne==null || authDetails.cOne==''
											?  '#1da6b8': authDetails.cOne.indexOf('-')!=-1 ? '#ffcc56' : '#1da6b8' )} ">

												{{authDetails.cOne==null || authDetails.cOne==''
											? "-":authDetails.cOne}}

											</strong>

											<!--						<#if journalDetails.cOne==null||journalDetails.cOne==''>-->
											<!--						- -->
											<!--						<#else>-->
											<!--						<strong-->
											<!--						<#if journalDetails.cOne==null||journalDetails.cOne==''>-->
											<!--						style=" color:#1da6b8;"-->
											<!--						<#elseif journalDetails.cOne?index_of("-") != -1 >-->
											<!--						style="color:#ffcc56;"-->
											<!--						<#else>-->
											<!--						style="color:#1da6b8;"-->
											<!--					</#if>-->
											<!--					>${journalDetails.cOne}</strong>-->
											<!--				</#if>-->
										</td>
										<td class="c2Value" data-value="${authDetails.cTwo}">
											<strong :style=" {color:( authDetails.cTwo==null || authDetails.cTwo==''
											?  '#1da6b8': authDetails.cTwo.indexOf('-')!=-1 ? '#ffcc56' : '#1da6b8' )} ">

												{{authDetails.cTwo==null || authDetails.cTwo==''
											? "-":authDetails.cTwo}}

											</strong>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="layui-panel" style="margin-top: 40px;">
							<table class="layui-table" style="text-align: center;margin: 0;">
								<colgroup>
									<col width="150">
									<col width="150">
								</colgroup>
								<thead>
									<tr class="layui-bg-black">
										<th colspan="4"
											style="text-align: center;font-size: 22px;font-family: 'Source Sans Pro', sans-serif;">
											Citation Distribution
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td colspan="2" style="text-align: center;width: 50%;">{{py1}}</td>
										<td colspan="2" style="text-align: center;">{{py2}}</td>
									</tr>
									<tr>
										<td style="text-align: center;">WOS</td>
										<td style="text-align: center;">Scopus</td>
										<td style="text-align: center;">WOS</td>
										<td style="text-align: center;">Scopus</td>
									</tr>
									<tr v-if="isLogin">


										<td style="color: #1da6b8">{{authDetails.jb.b1 == ''? "-" : authDetails.jb.b1}}
										</td>
										<td style="color: #1da6b8"></td>


										<td style="color: #1da6b8">{{authDetails.jb.b2 == ''? "-" : authDetails.jb.b2}}
										</td>
										<td style="color: #1da6b8"></td>

									</tr>
									<tr v-if="!isLogin">
										<td colspan="4">
											<a href="/login">
												<button type="button" class="layui-btn"
													style="background-color: #1da6b8;">Log in
													to
													view<i class="layui-icon layui-icon-password"></i></button>
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="layui-panel" style="margin-top: 40px;">
							<table class="layui-table" style="text-align: center;margin: 0;">
								<colgroup>
									<col width="150">
									<col width="150">
								</colgroup>
								<thead>
									<tr class="layui-bg-black">
										<th colspan="2"
											style="text-align: center;font-size: 22px;font-family: 'Source Sans Pro', sans-serif;">
											JIF
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style="text-align: center;">{{py1}}</td>
										<td style="text-align: center;">{{py2}}</td>
									</tr>
									<tr>
										<td style="color: #1da6b8">
										<!-- {{JIF.JIF2}} -->
										{{JIF && JIF.JIF2 ? JIF.JIF2 : ""}}
										</td>
										<td style="color: #1da6b8">
										<!-- {{JIF.JIF3}} -->
										{{JIF && JIF.JIF3? JIF.JIF3 : ""}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>


						<div class="layui-panel" style="margin-top: 40px;">
							<table class="layui-table" style="text-align: center;margin: 0;">
								<colgroup>
									<col width="150">
									<col width="150">
								</colgroup>
								<thead>
									<tr class="layui-bg-black">
										<th colspan="2"
											style="text-align: center;font-size: 22px;font-family: 'Source Sans Pro', sans-serif;">
											Highly Cited
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="ja && ja.highlyCitedStatus && ja.highlyCitedStatus=='Y'">
										<td style="color: #1da6b8">
											YES
										</td>
										<td>
											NO
										</td>
									</tr>
									<tr v-else-if="ja && ja.highlyCitedStatus && ja.highlyCitedStatus=='N'">

										<td>
											YES
										</td>
										<td style="color: #1da6b8">
											NO
										</td>
									</tr>

									<tr v-else colspan="2">
										<td>
											unknown
										</td>
										<td>
											unknown
										</td>


									</tr>
								</tbody>
							</table>
						</div>

						<div class="layui-panel" style="margin-top: 40px;">
							<table class="layui-table" style="margin: 0;">
								<colgroup>
									<col width="150">
								</colgroup>
								<thead>
									<tr class="layui-bg-black">
										<th colspan="2"
											style="text-align: center;font-size: 22px;font-family: 'Source Sans Pro', sans-serif;">
											Related Papers
										</th>
									</tr>
								</thead>
								<!--				<tbody id="view3">-->
								<!--				<tr class="loading3">-->
								<!--					<td><img src="../../assets/images/loading.gif" style="width: 70px;margin-left: 45%;"></td>-->
								<!--				</tr>-->
								<!--					<tr v-if="referenceList.length != 0" v-for="(item,index) of referenceList" :key="index">-->
								<!--						<td data-doi="{{item.doiD}}" class="references float-hand-color doiDDD">{{item.articleTitle}}</td>-->
								<!--					</tr>-->
								<!--					<div v-if="d.length === 0" style="margin-top: 80px;text-align: center;">-->
								<!--						<p class="layui-font-green layui-font-20">No Data</p>-->
								<!--					</div>-->
								<!--				</tbody>-->
								<tbody id="view3">
									<tr class="loading3">
										<td><img src="../../assets/images/loading.gif"
												style="width: 70px;margin-left: 45%;"></td>
									</tr>
									<!--					<tr v-for="(item,index) of referenceList" :key="index">-->

									<!--&lt;!&ndash;						<div v-if="item.lenght == 0" style="margin-top: 80px;text-align: center;">&ndash;&gt;-->
									<!--&lt;!&ndash;							<p class="layui-font-green layui-font-20">No Data</p>&ndash;&gt;-->
									<!--&lt;!&ndash;						</div>&ndash;&gt;-->
									<!--&lt;!&ndash;						<td data-doi="{{item.doiD}}" class="references float-hand-color doiDDD">{{item}}</td>&ndash;&gt;-->

									<!--					</tr>-->
								</tbody>

							</table>
						</div>

						<div class="layui-fluid" style="height: 500px;margin-top: 40px;" v-if="isLogin">
							<div class="layui-row">

								<div class="layui-col-md12 layui-col-centre" style="margin-top: 50px">

									<div id="acciimg5" width="252" height="252">
										<img src="@/assets/images/C.png" alt="">
										<span id="acci51" class="acci">
											<span class="fontf">CF-Ⅰ(<span
													class="co">{{authDetails.jb.b1}}</span>)</span>
											<span
												class="fontn">{{authDetails.cOne == ''? "-" : authDetails.cOne}}</span>
										</span>
										<span id="acci52" class="acci">
											<span class="fontf">CF-Ⅱ(<span
													class="co">{{authDetails.jb.b2}}</span>)</span><br>
											<span
												class="fontn">{{authDetails.cTwo == '' ? "-" : authDetails.cTwo}}</span>
										</span>

									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<teleport to="body">
			<el-dialog v-model="isChangePassword" title="Cite" width="40%" class="layui-fluid">
				<div id="citeHtml" class="layui-fluid" style="padding-top: 10px;max-width: 800px">
					<div class="layui-bg-gray" style="min-height: 80px; padding: 10px;margin-bottom: 20px;">
						<div class="layui-row">
							<div class="layui-col-md1 layui-col-left">
								<p>MLA</p>
							</div>
							<div class="layui-col-md10 layui-col-left" id="copyContent">
								{{journalDetails.authorsA}} ({{journalDetails.publicationYear}})
								.{{journalDetails.articleTitle}}
								.{{journalDetails.sourceTitle}},{{journalDetails.volumeV}}
								{{journalDetails.issueI}},{{journalDetails.doiD}}
							</div>
							<div class="layui-col-md1 layui-col-right">
								<p class="float-hand-color btn" id="btn" data-clipboard-target="#copyContent"
									:data-clipboard-text="text" @click="copyBtn"><strong
										style="font-size: 18px;">copy</strong>
								</p>
							</div>
						</div>
						<div class="layui-row" style="margin-top: 10px;">
							<div class="layui-col-md1 layui-col-left">
								<p>Export</p>
							</div>
							<div class="layui-col-md13 layui-col-left">
								<a style="margin-right: 20px;text-decoration: underline;" href="javascript:void(0)"
									class="leadingOut" data-type="BibTex">
									BibTex
								</a>
								<a style="margin-right: 20px;text-decoration: underline;" href="javascript:void(0)"
									class="leadingOut" data-type="EndNote">
									EndNote
								</a>
								<a style="margin-right: 20px;text-decoration: underline;" href="javascript:void(0)"
									class="leadingOut" data-type="RefMan">
									RefMan
								</a>
								<a style="margin-right: 20px;text-decoration: underline;" href="javascript:void(0)"
									class="leadingOut" data-type="NoteFirst">
									NoteFirst
								</a>
								<a style="margin-right: 20px;text-decoration: underline;" href="javascript:void(0)"
									class="leadingOut" data-type="NoteExpress">
									NoteExpress
								</a>
							</div>
						</div>
					</div>
				</div>
			</el-dialog>

			<el-dialog v-model="isGenerate" title="Cite" width="12%">
				<div id="reportHtml" class="layui-fluid" style="padding-top: 10px;max-width: 800px">
					<div class="layui-bg-gray" style="min-height: 80px; padding: 10px;margin-bottom: 20px;">
						<p>not available yet</p>
					</div>
				</div>
			</el-dialog>
		</teleport>

	</div>
</template>
<script>
	// import * as echarts from "echarts";
	import reOrCiItem from "@/components/item/reOrCiItem.vue"
	import $http from "@/request/http";
	// import {
	// 	reactive,
	// 	toRefs,
	// 	ref
	// } from "@vue/reactivity";
	
	import { Message } from 'element-ui';


	export default {
		components: {
			reOrCiItem
		},
		data() {
			return {
				
				isGenerate: false,
				//
				isChangePassword: false,
				isLogin: this.$store.getters.getIsLogin,
				//references子选项卡下标
				referencesIndex: 0,
				//citations子选项卡下标
				citationsIndex: 0,
				//一级选项卡
				layuiTitleIndex: 0,
				layuiTitleList: [{
					a: "References"
				}, {
					a: "Citations"
				}],
				referencesList: [{
					a: "All"
				}, {
					a: "Scopus"
				}, {
					a: "Others"
				}, {
					a: "WOS"
				}],
				citationsList: [{
						a: "All"
					}, {
						a: "WOS"
					}, {
						a: "Scopus"
					}, {
						a: "Others"
					},
					// {
					// 	a: "contributing"
					// },
				],
				dataCount: 0,
				currentPage: 1,
				JIF: {},
				ja: {},
				citsationsList: [],
				referenceList: [],
				journalDetails: {},
				authDetails: {
					cOne: "-",
					cTwo: "-",
					jb: {
						b1: "",
						b2: ""
					},
					angle1: "",
					angle2: ""
				},
				//references所有的列表（除了ALL）
				referencesListSelect: {
					scopusAll: {
						clickFlag: false,
						scopusList: []
					},
					otherAll: {
						clickFlag: false,
						otherList: []
					},
					wosAll: {
						clickFlag: false,
						wosList: []
					}
				},
				//citations所有的列表（除了ALL）
				citationsListSelect: {
					wosAll: {
						wosList: []
					},
					scopusAll: {
						scopusList: []
					},
					otherAll: {
						otherList: []
					}
				},

				//references 条件
				paramMap: {
					doi: "10.1306/05212019150",
					id: "0",
					page: 1,
					type: 0,
					//-1是全部，0是scopus，1是普通期刊，2是wos
					source_type: -1
				},
				//citations 条件
				paramMapTwo: {
					doi: "10.1306/05212019150",
					id: "0",
					page: 1,
					type: 1,
					//-1是全部，0是scopus，1是普通期刊，2是wos
					source_type: -1
				},
				title: "",
				py1: 0,
				py2: 0,
				authList: [],
				affiliationsList: [],
				affShow: true,
				//粘贴的text
				text: "",
				authOrcidList: []
			};
		},
		setup() {
			// 弹出框
			// let isChangePassword = ref(false);
			// let isGenerate = ref(false);

			// return {
			// 	// isChangePassword,
			// 	isGenerate,
			// };
		},
		methods: {
			copyBtn() {
				let _this = this
				let text = this.journalDetails.authorsA + " (" + this.journalDetails.publicationYear + ") ." + this
					.journalDetails.articleTitle + "." + this.journalDetails.sourceTitle + "," + this.journalDetails
					.volumeV + " " + this.journalDetails.issueI + "," + this.journalDetails.doiD
				// .uni-btn 点击复制按钮的class
				this.$copyText(text).then(e => {
						// _this.$message.success({
						// 	showClose: true,
						// 	duration: 1500,
						// 	dangerouslyUseHTMLString: true,
						// 	message: "<i class=\"layui-icon layui-icon-face-smile-fine\"></i> Copy succeeded！",
						// });
						Message({
							dangerouslyUseHTMLString: true,
							// iconClass : 'iconColor',
							message: '<i class="layui-icon layui-icon-face-smile-fine"></i> Copy succeeded！',
							type: 'success',
						})
						
						// this.$message({
						//           message: 'Copy succeeded！',
						//           type: 'success'
						//         });
						
						console.log('Copy success', e);
					},
					e => {
						_this.$message.error({
							showClose: true,
							duration: 1500,
							message: "Copy error！",
						});
						console.log('Copy error', e);
					}
				)
			},
			//子选项卡选择
			referencesBtn(index) {
				this.referencesIndex = index;
				this.paramMap.source_type = index - 1


				// if (index != 0) {
				// 	if (index == 1) {
				// 		if (!this.referencesListSelect.scopusAll.clickFlag) {
				// 			this.referencesListSelect.scopusAll.clickFlag = true
				// 			this.getReferencesList()
				// 		}
				// 	} else if (index == 2) {
				// 		if (!this.referencesListSelect.otherAll.clickFlag) {
				// 			this.referencesListSelect.otherAll.clickFlag = true
				// 			this.getReferencesList()
				// 		}
				// 	} else if (index == 3) {
				// 		if (!this.referencesListSelect.wosAll.clickFlag) {
				// 			this.referencesListSelect.wosAll.clickFlag = true
				// 			this.getReferencesList()
				// 		}
				// 	}
				// }
			},
			//子选项卡选择
			citationsBtn(index) {

				this.citationsIndex = index;
				this.paramMapTwo.source_type = index - 1

				if (index != 0) {
					if (index == 1) {
						if (!this.citationsListSelect.scopusAll.clickFlag) {
							this.citationsListSelect.scopusAll.clickFlag = true
							this.getCitationList()
						}
					} else if (index == 2) {
						if (!this.citationsListSelect.otherAll.clickFlag) {
							this.citationsListSelect.otherAll.clickFlag = true
							this.getCitationList()
						}
					} else if (index == 3) {
						if (!this.citationsListSelect.wosAll.clickFlag) {
							this.citationsListSelect.wosAll.clickFlag = true
							this.getCitationList()
						}
					}
				}

			},
			//
			pageCliOne(index) {
				// console.log("index1总页数=========================》",index);
				this.paramMap.page = index;
				this.getReferencesList();
			},
			pageCliTwo(index) {
				// console.log("index2总页数=========================》",index);
				this.paramMapTwo.page = index;
				this.getCitationList();
			},
			//底部references的分页集合
			getReferencesList() {
				// console.log("asdjoqwoe===>",this.$route.params.id)
				$http.post("getReferenceListOrCitationList", this.paramMap, true).then(res => {
					if (this.paramMap.source_type == -1) {
						this.referenceList = res.data.list
					} else if (this.paramMap.source_type == 0) {
						this.referencesListSelect.scopusAll.scopusList = res.data.list
					} else if (this.paramMap.source_type == 1) {
						this.referencesListSelect.otherAll.otherList = res.data.list
					} else if (this.paramMap.source_type == 2) {
						this.referencesListSelect.wosAll.wosList = res.data.list
					}


				}).catch(err => {
					console.log("123123", err);
				})
			},
			//底部citation的分页集合
			getCitationList() {
				$http.post("getReferenceListOrCitationList", this.paramMapTwo, true).then(res => {

					if (this.paramMapTwo.source_type == -1) {
						this.citsationsList = res.data.list
					} else if (this.paramMapTwo.source_type == 0) {
						this.citationsListSelect.scopusAll.scopusList = res.data.list
					} else if (this.paramMapTwo.source_type == 1) {
						this.citationsListSelect.otherAll.otherList = res.data.list
					} else if (this.paramMapTwo.source_type == 2) {
						this.citationsListSelect.wosAll.wosList = res.data.list
					}

				}).catch(err => {
					console.log("123123", err);
				})
			},
			detailsList() {
				// console.log("asdjoqwoe===>",this.$route.params.id)
				$http.post("journalDetails", this.paramMap).then(res => {
					this.journalDetails = res.data.journalDetails;
					this.JIF = res.data.JIF;
					this.py1 = res.data.py1;
					this.py2 = res.data.py2;
					this.authList = res.data.authList;
					this.affiliationsList = res.data.affiliationsList;
					this.authOrcidList = res.data.authOrcidList;
					this.ja = res.data.ja;
					console.log("asdasdasd", res);
				}).catch(err => {
					console.log("123123", err);
				})
			},
			authPaperDetails() {

				$http.authTrue("journalDetails", this.paramMap).then(res => {
					this.authDetails.cOne = res.data.cOne
					this.authDetails.cTwo = res.data.cTwo
					this.authDetails.jb = res.data.jb
					this.authDetails.angle1 = res.data.angle1
					this.authDetails.angle2 = res.data.angle2
				}).catch(e => {

				})
			},
			citeBtn() {

			}
		},
		mounted() {

		},
		created() {

			this.paramMap.id = this.$route.query.id
			this.paramMapTwo.id = this.$route.query.id
			// this.title = this.$route.query.title
			
			this.detailsList()
			
			// this.getReferencesList()
			// this.getCitationList()

			//已登录
			if (this.isLogin) {
				this.authPaperDetails()
			}

		},

	};
</script>

<style lang="scss" scoped>
	.ad_fa {
    	border-bottom: 1px dashed #e6e6e6;
    	padding: 10px 0;	

		strong {
			display: inline-block;
		}
	}

	::v-deep .el-button--text {
		margin-right: 15px;
	}

	::v-deep .el-select {
		width: 300px;
	}

	::v-deep .el-input {
		width: 300px;
	}

	::v-deep .dialog-footer button:first-child {
		margin-right: 10px;
	}
	
	::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
		background-color: #1da6b8;
		color:#fff;
	}
	::v-deep .el-pager li:hover{
		color: #1da6b8;
	}
	::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover{
		color: #1da6b8;
	}
</style>
<!-- 图标样式设置 -->
<style scoped>
	.acci {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 35%;
	}


	#acciimg5 {
		width: 100%;
		height: 330px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.fontf {
		color: #C77F2F;
		font-size: 20px;
	}

	#acciimg5>img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 115%;
	}

	.fontn {
		color: #C77F2F;
		font-size: 26px;
		text-align: center;
		width: 100%;
	}
</style>